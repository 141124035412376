import React from "react"

import MainBannerLogo from "./assets/MainBannerLogo.png"
import MainMediaBanner from "./assets/MediaMainBanner.webp"
import MainMediaBannerFallback from "./assets/MediaMainBannerFallback.png"
import MediaMainBannerMobile from "./assets/MediaMainBannerMobile.webp"
import DesktopMediaMainBanner from "./assets/DesktopMediaMainBanner.png"

import styles from "./styles.module.scss"

export const mainBanner = {
	banner: {
		mediaContent: (
			<picture className={styles.mainBanner__picture}>
				<source
					srcSet={MainMediaBanner}
					media="(min-width: 1024px)"
					type="image/webp"
				/>
				<source
					srcSet={MainMediaBannerFallback}
					media="(min-width: 1024px)"
					type="image/png"
				/>
				<img
					className={styles.mainBanner__image}
					src={MediaMainBannerMobile}
					alt="Кофейня Park47"
					loading="lazy"
				/>
			</picture>
		),
		desktopBanner: (
			<img src={DesktopMediaMainBanner} alt="Кофейня Park47" loading="lazy" />
		),
		logo: (
			<img
				className={styles.logo}
				src={MainBannerLogo}
				alt="Логотип"
				loading="lazy"
			/>
		),
		quote: (
			<>
				Несезон, спальный район и&nbsp;другие обстоятельства.{" "}
				<span className={styles.heading}>
					<span className={styles.quote}>Кейс «PARK47»</span>
				</span>
			</>
		),
	},
	heading: "Как сделать спешелти-кофе популярным в спальном районе",
	textarea: (
		<>
			Кофейню «PARK47» рядом с&nbsp;Воронцовским парком местные жители любят
			за&nbsp;необычные вкусы, приятную команду и&nbsp;красивый интерьер.
			Основательница Инга Добыш рассказала про свой путь от&nbsp;мамы
			в&nbsp;декрете до&nbsp;владельца бизнеса. А&nbsp;шеф-бариста Игорь Сидоров
			поделился тем, за&nbsp;что любит свою работу, и&nbsp;приоткрыл дверь
			во&nbsp;внутреннюю кухню кофейни.
		</>
	),
	video: {
		src: "https://www.youtube.com/embed/NW_kljPCJQ8",
		textarea: (
			<p className={styles.textarea}>
				Как освоить профессию бариста <br className={styles.contentSmallShow} />{" "}
				и начать свой бизнес
			</p>
		),
	},
}
