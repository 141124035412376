import React from "react"
import classNames from "classnames"

import Fancybox from "../../../../../components/_V3/Fancybox"

import PromptIconOne from "./assets/PromptIconOne.png"
import PromptIconTwo from "./assets/PromptIconTwo.png"
import PromptIconThree from "./assets/PromptIconThree.png"
import PromptIconFour from "./assets/PromptIconFour.png"
import PromptIconFive from "./assets/PromptIconFive.png"
import PromptIconSix from "./assets/PromptIconSix.png"
import PromptIconSeven from "./assets/PromptIconSeven.png"

import MediaContentOne from "./assets/MediaContentOne.webp"
import MediaContentTwo from "./assets/MediaContentTwo.webp"
import MediaContentThree from "./assets/MediaContentThree.webp"
import MediaContentFour from "./assets/MediaContentFour.webp"
import MediaContentFive from "./assets/MediaContentFive.webp"
import MediaContentSix from "./assets/MediaContentSix.webp"

import styles from "./styles.module.scss"

export const headingTextareaAndPrompt = [
	{
		heading:
			"Инга: В нашем районе не было места с хорошим кофе, и мы решили его открыть",
		textarea: (
			<>
				Два года назад я&nbsp;поняла, что мне пора выходить из&nbsp;роли просто
				мамы и&nbsp;снова входить в&nbsp;материальный мир. И&nbsp;подумала,
				почему&nbsp;бы не&nbsp;открыть кофейню, ведь в&nbsp;районе Воронцовского
				парка, где я&nbsp;живу, не&nbsp;было места с&nbsp;хорошим кофе. Несмотря
				на&nbsp;отсутствие знаний и&nbsp;опыта, решила попробовать&nbsp;&mdash;
				это был серьёзный, ответственный и&nbsp;рискованный для меня шаг.
			</>
		),
		prompt: (
			<div className={classNames(styles.promptTypeOne, styles.promptIconOne)}>
				<img
					className={classNames(styles.icon, styles.icon__one)}
					src={PromptIconOne}
					alt="Иконка 1"
					loading="lazy"
				/>
				<p>
					Освоила профессию бариста с&nbsp;нуля, периодически сама стою
					за&nbsp;стойкой
				</p>
			</div>
		),
		mediaContent: (
			<div className={classNames(styles.mediaContent, styles.mediaContentOne)}>
				<Fancybox>
					<a
						data-fancybox="image1"
						href={MediaContentOne}
						className={classNames(styles.media, styles.fancy)}
					>
						<img
							className={styles.mediaContent__coverImg}
							src={MediaContentOne}
							alt="открытие кофейни park47"
							loading="lazy"
						/>
					</a>
				</Fancybox>

				<p className={classNames(styles.desc, styles.descOne)}>
					Два года назад я&nbsp;решила выйти из&nbsp;роли мамы и&nbsp;стать
					предпринимателем
				</p>
			</div>
		),
		textarea2: (
			<>
				Я&nbsp;нашла инвесторов, а&nbsp;сама стала координатором
				проекта&nbsp;&mdash; контролировала строительные работы, общалась
				с&nbsp;технологами, архитекторами, дизайнерами. А&nbsp;параллельно
				освоила новую для себя профессию бариста с&nbsp;нуля в&nbsp;Академии
				Бариста и&nbsp;теперь периодически сама стою за&nbsp;стойкой.
			</>
		),
	},
	{
		heading: "Франшизе предпочли концепцию спешелти-кофейни",
		textarea: (
			<>
				Сперва мы&nbsp;с&nbsp;партнёрами думали о&nbsp;франшизе. Даже
				договорились с&nbsp;одной сетью и&nbsp;получили проект интерьера,
				но&nbsp;он&nbsp;не&nbsp;пришёлся нам по&nbsp;душе. Тогда
				я&nbsp;обратилась за&nbsp;помощью к&nbsp;знакомому архитектору,
				и&nbsp;он&nbsp;представил нас своим друзьям: Богдану Прокопчуку
				и&nbsp;Лилии Гадельшиной&nbsp;&mdash; наши технологи открытия
				и&nbsp;многократные финалисты Российских чемпионатов бариста.
			</>
		),
		prompt: (
			<div className={classNames(styles.promptTypeOne, styles.promptIconTwo)}>
				<img
					className={classNames(styles.icon, styles.icon__two)}
					src={PromptIconTwo}
					alt="Иконка 2"
					loading="lazy"
				/>
				<p>
					Оборудование&nbsp;&mdash; одно из&nbsp;самого дорогого, что есть
					в&nbsp;PARK47
				</p>
			</div>
		),
		mediaContent: (
			<div
				className={classNames(
					styles.mediaContent,
					styles.mediaContent__rotateLeft,
					styles.mediaContentTwo
				)}
			>
				<Fancybox>
					<a
						data-fancybox="image2"
						href={MediaContentTwo}
						className={classNames(styles.media, styles.fancy)}
					>
						<img
							className={styles.mediaContent__coverImg}
							src={MediaContentTwo}
							alt="интерьер кофейни"
							loading="lazy"
						/>
					</a>
				</Fancybox>

				<p className={classNames(styles.desc, styles.descTwo)}>
					В&nbsp;«PARK47» бетонные стены, мраморная стойка и&nbsp;акцентное
					освещение
				</p>
			</div>
		),
		textarea2: (
			<>
				Ребята трудились над внутренней эргономикой рабочего пространства,
				помогли выбрать оборудование и&nbsp;разработали меню&nbsp;&mdash; одно
				из&nbsp;самого дорогого, что есть в&nbsp;нашем проекте. Архитектурное
				бюро OFFICE1234&nbsp;во главе со&nbsp;Станиславом Титовым отвечает
				за&nbsp;дизайн интерьера пространства «PARK47».
				<br />
				<br />
				Задача архитектора была создать простое и&nbsp;уютное место для
				наслаждения едой и&nbsp;кофе.{" "}
				<strong>
					В&nbsp;интерьере реализован современный европейский функционализм
				</strong>{" "}
				с&nbsp;чёткой простой геометрией и&nbsp;красивыми фактурами натурального
				мрамора и&nbsp;бетона. Пространство лишено всего ненужного. Простота
				со&nbsp;сложно выверенными пропорциями и&nbsp;объёмами. Акценты
				в&nbsp;цветовых решениях и&nbsp;освещении.
				<br />
				<br />
				Спешелти кофе&nbsp;&mdash; это зерно высокого качества, которое напрямую
				покупают с&nbsp;плантаций у&nbsp;фермеров, знают где и&nbsp;как оно
				росло, как обрабатывалось. Этот класс присуждают только арабике. Чтобы
				получить класс спешелти кофе, требуется набрать не&nbsp;менее
				80&nbsp;баллов. И&nbsp;для этого необходимо качественное оборудование,
				профессиональные знания и&nbsp;мастерство бариста. Всё это
				мы&nbsp;собрали в&nbsp;«PARK47» благодаря сильной команде.
			</>
		),
	},
	{
		heading: "«PARK47» — неслучайное название ",
		textarea: (
			<>
				Мы&nbsp;находимся на&nbsp;улице известного архитектора Власова
				и&nbsp;хотели назвать кофейню &laquo;Архитектор&raquo;. Дизайнер Макси
				Шилов разработал логотип и&nbsp;элементы фирменного стиля,
				и&nbsp;придумал название для нашей кофейни «PARK47». Он&nbsp;задал два
				вопроса: где расположен проект и&nbsp;какой номер дома. &laquo;Рядом
				с&nbsp;Воронцовским парком в&nbsp;доме
				&#8470;&nbsp;47&raquo;,&nbsp;&mdash; ответили&nbsp;мы. &laquo;Тогда
				PARK47&raquo;,&nbsp;&mdash; сказал Макс, и&nbsp;это название пришлось
				нам по&nbsp;душе. Затем началась магия чисел: мы&nbsp;вели строительство
				7&nbsp;месяцев и&nbsp;случайно открылись именно 4&nbsp;июля,
				то&nbsp;есть 04.07.
			</>
		),
		prompt: (
			<div className={classNames(styles.promptTypeOne, styles.promptIconThree)}>
				<img
					className={classNames(styles.icon, styles.icon__three)}
					src={PromptIconThree}
					alt="Иконка 3"
					loading="lazy"
				/>
				<p>
					В&nbsp;названии «PARK47»&nbsp;&mdash; магия чисел. Вели строительство
					7&nbsp;месяцев, открылись 04.07
				</p>
			</div>
		),
	},
	{
		heading: "Мы открылись в сложное время, но нас все поддержали",
		textarea: (
			<>
				На&nbsp;протяжении всей подготовки к&nbsp;открытию мы&nbsp;сталкивались
				с&nbsp;разными трудностями. Например, из-за пандемии команду пришлось
				набирать онлайн. Когда мы&nbsp;уже расставили оборудование, закупили
				зерно, привезли столы и&nbsp;стулья, мы&nbsp;сняли защитное покрытие
				с&nbsp;пола&nbsp;&mdash; увидели, что стяжка, которую залили ещё
				в&nbsp;январе, не&nbsp;просохла. Видимо, была нарушена технология.{" "}
				<strong>Пришлось отложить запуск на&nbsp;целый месяц.</strong>
			</>
		),
		prompt: (
			<div className={classNames(styles.promptTypeOne, styles.promptIconFour)}>
				<img
					className={classNames(styles.icon, styles.icon__four)}
					src={PromptIconFour}
					alt="Иконка 4"
					loading="lazy"
				/>
				<p>Через два месяца после открытия кофейня стала приносить прибыль</p>
			</div>
		),
		mediaContent: (
			<div
				className={classNames(styles.mediaContent, styles.mediaContentThree)}
			>
				<Fancybox>
					<a
						data-fancybox="image3"
						href={MediaContentThree}
						className={classNames(styles.media, styles.fancy)}
					>
						<img
							className={styles.mediaContent__coverImg}
							src={MediaContentThree}
							alt="подбор команды в кофейню"
							loading="lazy"
						/>
					</a>
				</Fancybox>

				<p className={classNames(styles.desc, styles.descThree)}>
					Из-за пандемии команду пришлось набирать онлайн
				</p>
			</div>
		),
		textarea2: (
			<>
				Когда до&nbsp;новой даты открытия оставалась неделя, в&nbsp;Москве ввели
				QR-коды из-за пандемии. Лето в&nbsp;принципе несезон для кофе,
				а&nbsp;тут ещё и&nbsp;это. Но&nbsp;на&nbsp;открытие пришло около
				100&nbsp;человек: не&nbsp;все могли войти внутрь,
				но&nbsp;мы&nbsp;работали навынос. Нас поддержали и&nbsp;наши
				соседи&nbsp;&mdash; кофейня Coffeeshere. В&nbsp;день нашего открытия,
				они рекомендовали своим гостям зайти к&nbsp;нам и&nbsp;поддержать
				в&nbsp;столь сложное время. В&nbsp;итоге уже через два месяца после
				открытия кофейня стала приносить прибыль. Сейчас мы&nbsp;находимся
				в&nbsp;процессе окупаемости.
			</>
		),
	},
	{
		heading: "Наш кофе невозможно забыть",
		textarea: (
			<>
				Когда мы&nbsp;только открылись, нам нужно было, чтобы гости о&nbsp;нас
				узнали и&nbsp;запомнили. Мы&nbsp;шли в&nbsp;парк и&nbsp;угощали
				посетителей кофе. Кстати, первый кофе, который мы&nbsp;дали
				попробовать,&nbsp;&mdash; уникальный Колумбия Гектор Дазе с&nbsp;нотами
				клубники и&nbsp;шампанского. Никто не&nbsp;мог поверить, что такой
				необычный вкус получается без всяких добавок. За&nbsp;пару дней до
				открытия кофейни бариста стала финалисткой отборочного этапа Чемпионата
				в&nbsp;Иркутске по&nbsp;завариванию с&nbsp;данным кофе.
			</>
		),
	},
	{
		heading: "Секретный ингредиент успеха — люди",
		textarea: (
			<>
				Мне вообще очень повезло с&nbsp;людьми. Во-первых,
				с&nbsp;профессиональной командой, с&nbsp;которой мы&nbsp;открывались.
				Именно их&nbsp;концепция здесь заложена, они многому нас научили,
				привили нам правильный вкус. Во-вторых, большая удача собрать команду
				тех, кто теперь всё это поддерживает. Например, наш шеф-бариста Игорь.
				Он&nbsp;был нашим первым гостем, много раз приходил выпить кофе.
				А&nbsp;сейчас возглавляет команду и&nbsp;даже представлял кофейню
				на&nbsp;чемпионате.
			</>
		),
		prompt: (
			<div className={classNames(styles.promptTypeOne, styles.promptIconFive)}>
				<img
					className={classNames(styles.icon, styles.icon__five)}
					src={PromptIconFive}
					alt="Иконка 5"
					loading="lazy"
				/>
				<p>Первый гость стал бариста и возглавил команду</p>
			</div>
		),
		mediaContent: (
			<div
				className={classNames(
					styles.mediaContent,
					styles.mediaContent__rotateLeft,
					styles.mediaContentFour
				)}
			>
				<Fancybox>
					<a
						data-fancybox="image4"
						href={MediaContentFour}
						className={classNames(styles.media, styles.fancy)}
					>
						<img
							className={styles.mediaContent__coverImg}
							src={MediaContentFour}
							alt="бариста в кофейне"
							loading="lazy"
						/>
					</a>
				</Fancybox>

				<p className={classNames(styles.desc, styles.descFour)}>
					Игорь был нашим первым гостем, а сейчас возглавляет команду
				</p>
			</div>
		),
		textarea2: (
			<>
				<strong>
					Мы&nbsp;вошли в&nbsp;пятёрку лучших кофейных мест 2021 года
				</strong>{" "}
				по&nbsp;версии портала coffeemap.ru. Для нас это большое признание нашей
				работы. Старались и&nbsp;гордимся. Постоянно идём вперед, обучаемся.
				Сейчас готовимся к&nbsp;чемпионату Russian Coffee Cup.
				Ну&nbsp;и&nbsp;в-третьих, у&nbsp;нас приятные гости. Люди, которые живут
				в&nbsp;этом районе,&nbsp;&mdash; открытые, добрые. Они делятся своими
				впечатлениями и&nbsp;помогают нам становиться лучше.
			</>
		),
	},
	{
		heading: "Игорь: Думаю, наш секретный ингредиент — это Инга",
		textarea: (
			<>
				Человек создал заведение не&nbsp;ради того, чтобы зарабатывать деньги,
				а&nbsp;ради того, чтобы дать людям в&nbsp;спальном районе реально
				качественный продукт. И&nbsp;она не&nbsp;пустила всё на&nbsp;самотёк,
				а&nbsp;практически каждый день за&nbsp;всем следит, вкладывает душу,
				прилагает реальные усилия. Это удивительно&nbsp;&mdash; я&nbsp;такого
				раньше не&nbsp;видел. У&nbsp;нас в&nbsp;целом чудесная команда.
				Мы&nbsp;все друг друга уважаем, поддерживаем, и&nbsp;это, по-моему,
				самое главное.
			</>
		),
	},
	{
		heading: "А для всего остального есть Quick Resto",
		textarea: (
			<>
				В нашей кофейне мы используем очень удобное программное обеспечение
				Quick Resto:
				<br />
				<ul className={classNames(styles.textarea_list, styles.ul)}>
					<li>
						У&nbsp;каждого сотрудника есть <strong>личный код</strong>, поэтому
						мы&nbsp;можем детально отслеживать выручку, вместе с&nbsp;ребятами
						анализировать отчёты и&nbsp;поощрять их&nbsp;за&nbsp;активные
						продажи.
					</li>
					<li>
						<strong>Можем сами списывать продукты,</strong> чтобы немного
						разгрузить бухгалтера.
					</li>
					<li>
						Благодаря Quick Resto у&nbsp;нас появилась{" "}
						<strong>система бонусов и&nbsp;подарочные сертификаты</strong>.
						В&nbsp;общем&nbsp;&mdash; много полезных функций.
					</li>
				</ul>
			</>
		),
		prompt: (
			<div className={classNames(styles.promptTypeOne, styles.promptIconSix)}>
				<img
					className={classNames(styles.icon, styles.icon__six)}
					src={PromptIconSix}
					alt="Иконка 6"
					loading="lazy"
				/>
				<p>
					Наши гости делятся впечатлениями и&nbsp;помогают нам становиться лучше
				</p>
			</div>
		),
		mediaContent: (
			<div className={classNames(styles.mediaContent, styles.mediaContentFive)}>
				<Fancybox>
					<a
						data-fancybox="image5"
						href={MediaContentFive}
						className={classNames(styles.media, styles.fancy)}
					>
						<img
							className={styles.mediaContent__coverImg}
							src={MediaContentFive}
							alt="система автоматизации для кофейни"
							loading="lazy"
						/>
					</a>
				</Fancybox>

				<p className={classNames(styles.desc, styles.descFive)}>
					Quick Resto разгружает бухгалтера, анализирует отчёты и&nbsp;следит
					за&nbsp;финансами
				</p>
			</div>
		),
		textarea2: (
			<span className={styles.text}>
				В&nbsp;других заведениях я&nbsp;использовал разные программы,
				но&nbsp;все они уступают по&nbsp;понятности и&nbsp;удобству. Quick Resto
				можно полностью настроить под свои задачи, выбрать нужные категории,
				чтобы всё выглядело суперкомпактно и&nbsp;работало логично.
			</span>
		),
	},
	{
		heading: "Инга: Quick Resto — верный помощник руководителя",
		textarea: (
			<>
				Система автоматизации Quick Resto настолько простая, что, даже когда
				приходит новый сотрудник,{" "}
				<strong>обучение занимает минимум времени.</strong> Всё очень наглядно.
				Меня как руководителя кофейни волнуют финансовые вопросы&nbsp;&mdash;
				следить за&nbsp;этим мне помогает система отчётности и&nbsp;финансовой
				аналитики. Здесь мы&nbsp;проводим инвентаризацию, смотрим расход,
				приход.
			</>
		),
	},
	{
		heading: (
			<>
				Для начинающих <br className={styles.contentSmallShow} /> у меня есть
				один совет и одно предостережение
			</>
		),
		textarea: (
			<>
				Главное для владельца бизнеса&nbsp;&mdash; полностью погружаться
				в&nbsp;технологические процессы и&nbsp;с&nbsp;вниманием относиться
				к&nbsp;своему делу. Мне очень помогает&nbsp;то, что я&nbsp;изнутри знаю
				всё, что происходит в&nbsp;кофейне, и&nbsp;именно поэтому я&nbsp;могу
				это контролировать.
			</>
		),
		prompt: (
			<div className={classNames(styles.promptTypeOne, styles.promptIconSeven)}>
				<img
					className={classNames(styles.icon, styles.icon__seven)}
					src={PromptIconSeven}
					alt="Иконка 7"
					loading="lazy"
				/>
				<p>Высокая аренда может погубить перспективный бизнес</p>
			</div>
		),
		mediaContent: (
			<div
				className={classNames(
					styles.mediaContent,
					styles.mediaContent__rotateLeft,
					styles.mediaContentSix
				)}
			>
				<Fancybox>
					<a
						data-fancybox="image6"
						href={MediaContentSix}
						className={classNames(styles.media, styles.fancy)}
					>
						<img
							className={styles.mediaContent__coverImg}
							src={MediaContentSix}
							alt="технологические процессы в кофейне"
							loading="lazy"
						/>
					</a>
				</Fancybox>

				<p className={classNames(styles.desc, styles.descSix)}>
					Главное для владельца бизнеса&nbsp;&mdash; полностью погружаться
					во&nbsp;все процессы
				</p>
			</div>
		),
		textarea2: (
			<>
				А&nbsp;ещё очень важно на&nbsp;старте не&nbsp;торопиться с&nbsp;выбором
				помещения. Высокая стоимость аренды может погубить даже перспективный
				бизнес. Лучше всего найти партнёра со&nbsp;своим помещением, как это
				сделали мы&nbsp;&mdash; это дало нам время, чтобы пережить все трудности
				и&nbsp;начать выходить в&nbsp;плюс.
			</>
		),
	},
	{
		heading: (
			<>
				Сейчас <br className={styles.contentSmallShow} /> мы нацелены на
				совершенствование, а не на масштабирование
			</>
		),
		textarea: (
			<>
				Гости часто спрашивают, собираемся&nbsp;ли мы&nbsp;открывать ещё
				кофейни. Например, в&nbsp;том&nbsp;же Воронцовском парке. Но&nbsp;там
				высокая стоимость аренды, подъезд к&nbsp;точкам не&nbsp;удобный
				и&nbsp;нет освещения и&nbsp;водопровода. Многие там закрываются,
				и&nbsp;для нас это сомнительная перспектива. Сейчас наша
				задача&nbsp;&mdash; делать вкусный качественный продукт и&nbsp;делиться
				с&nbsp;гостями хорошим настроением. Только ради этого
				мы&nbsp;и&nbsp;работаем.
			</>
		),
	},
]
